<template>
  <div>
    <h3 class="sec-header">Ihr Ergebnis</h3>
    <v-card>
      <v-card-text style="font-size:1em;line-height:inherit">
        <div class="d-flex xx-title">
          <span class>Bemessungsgrundlage</span>
          <span
            class="flex-grow-1 text-right"
          >{{ config.getTotal() - config.getAuslagenpauschale() | currency }}</span>
        </div>
        <div class="d-flex xx-title">
          <span class>Auslagenpauschale</span>
          <span class="flex-grow-1 text-right">{{ config.getAuslagenpauschale() | currency }}</span>
        </div>
        <div class="d-flex xx-title">
          <span class>{{ config.priceName }} (netto)</span>
          <span class="flex-grow-1 text-right">{{ config.getTotal() | currency }}</span>
        </div>
        <div class="d-flex xx-caption">
          <span class>zzgl. 19% gesetzliche Umsatzsteuer</span>
          <span class="flex-grow-1 text-right">{{ config.getTotal() * 0.19 | currency }}</span>
        </div>
        <div class="d-flex title">
          <span class>{{ config.priceName }} (brutto)</span>
          <span class="flex-grow-1 text-right">{{ config.getTotal() * 1.19 | currency }}</span>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['config']
};
</script>

<style>
</style>
