<template>
  <div v-if=0>
    <h3 class="sec-header">Unverbindliches Angebot anfordern</h3>
    <v-card>
      <v-card-text class="pb-0 pt-6">
        <v-row>
          <v-col cols="6" class="py-0">
            <v-text-field v-bind="commonInputProps" label="Name" v-model="address.name"/>
            <v-text-field v-bind="commonInputProps" label="Straße und Haus-Nr." v-model="address.street"/>
            <v-row>
              <v-col cols="4" class="py-0">
                <v-text-field v-bind="commonInputProps" label="PLZ" v-model="address.zip"/>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field v-bind="commonInputProps" label="Ort" v-model="address.city"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="py-0">
              
                <v-select
                v-model="address.year"
                  v-bind="commonInputProps"
                  label="Jahr"
                  :items="['2019','2018','2017','2016',]"
                />

            <v-text-field v-bind="commonInputProps" label="Email" v-model="address.mail"/>
            <v-text-field v-bind="commonInputProps" label="Telefon" v-model="address.phone"/>
          </v-col>
        </v-row>
        <v-checkbox class="mt-0">
          <template v-slot:label>
            <div>
              Ich habe die
              <a
                href="https://www.honorarrechner.net/datenschutzerklaerung"
                target="_blank"
                @click.stop
              >Datenschutzerklärung</a>
              zur Kenntnis genommen und bin damit einverstanden.
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
    </v-card>

    <div class="my-6 d-flex">
      <div class="caption align-self-center" v-if="0">
        powered by
        <a
          href="https://www.honorarrechner.net"
          target="_blank"
          style="color:inherit"
        >HonorarRechner.net</a>
      </div>
      <v-spacer />

      <v-btn
        depressed
        text
        color="secondary"
        class="mr-4"
        href="est-angebot-demo.pdf"
        target="_blank"
      >Drucken</v-btn>
      <v-btn depressed @click="sendMail()" color="primary">Anfrage senden</v-btn>
    </div>
  </div>
</template>

<script>
// import config from '@/data/config';
import axios from 'axios';

export default {
  props: ['address', 'config'],
  data() {
    return {
      // address: config.address,
      commonInputProps: {
        outlined: true,
        dense: true,
        autoComplete: 'new-password'
        //hideDetails:true,
      }
    };
  },
  methods: {
    sendMail() {
      let inputs = this.config.getInputs();
      
      let routeName = this.$route.name;
      if (routeName.includes('Recover'))
      {
        routeName = routeName.split('Recover')[0];
      }
      inputs.calculator = routeName;
      axios.post('http://localhost:3000/mail', inputs);
    },
  },
};
</script>

<style>
</style>