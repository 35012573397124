let inputs = {
    est: {
        "anlagenChecked": [
            true,
            true,
            true,
            true,
            false
        ],
        "anlagen": [
            [
                [
                    2424,
                    2424
                ]
            ],
            [
                [
                    242424,
                    424
                ],
                [
                    null,
                    null
                ]
            ],
            [
                [
                    2424,
                    2424
                ],
                [
                    8080,
                    8
                ],
                [
                    8080,
                    8008
                ]
            ],
            [
                [
                    2424,
                    null
                ],
                [
                    2424,
                    2424
                ]
            ],
            [
                [
                    null,
                    null
                ]
            ]
        ],
        "options": [
            true,
            true,
            true,
            false
        ]
    },
    address: {
        name: 'Lennart',
        year: '2019',
        street: 'Nerongsallee 38',
        mail: 'lennart.buettner@icloud.com',
        zip: '24939',
        city: 'Flensburg',
        phone: '131313131313'
    }
};

export default inputs;