<template>
  <div>
    <div class="main-wrapper">
      <v-content style="max-width: 1200px; margin: 0 auto">
        <h1 class="display-1 mb-6 pb-2" style>{{ config.name }}</h1>

        <h3 class="sec-header">Ihre Angaben</h3>
        <!-- <div>BEI OPTION "VORSYSTEM VORHANDEN" WARNUNG FUER INDIVIDUELLES ANGEBOT</div> -->
        <v-card>
          <v-card-text>
            <v-select
              dense
              outlined
              :items="config.modes"
              v-model="config.selected"
              label="Unternehmensform/Abschlusstyp"
              hide-details
              @change="adjustHeight()"
            />

            <template v-if="config.selected != null">
              <v-row class="mt-2">
                <v-col cols="12" :sm="12/inputs.length" v-for="(input, i) in inputs" :key="i">
                  <CurrencyField
                    outlined
                    dense
                    class="currency-input"
                    :label="input.name"
                    :tooltip="input.tooltip"
                    v-model="input.value"
                    hide-details
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
        <template v-if="config.selected != null">
          <h3 class="sec-header">Inklusivleistungen</h3>
          <v-card>
            <v-card-text style="font-size:1em;line-height:inherit">
              <ul>
                <li v-for="(option, i) in includedServices" :key="i">{{option}}</li>
              </ul>
            </v-card-text>
          </v-card>

          <h3 class="sec-header">Zusatzleistungen</h3>
          <v-card>
            <v-card-text>
              <div class="d-flex" v-for="(option, i) in options" :key="i">
                <v-checkbox
                  style="margin: 0 0 -19px 0;"
                  class="pa-0"
                  v-model="option.checked"
                  :label="option.name"
                />
                <v-tooltip right v-if="option.info" max-width="300">
                  <template v-slot:activator="{ on }">
                    <v-btn icon depressed small v-on="on" style="margin: -1px 0 0 5px;">
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ option.info }}</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </template>

        <template v-if="config.selected != null">
          <Ergebnis :config="config" />
          <div class="fineprint mt-6">
            Für die Jahresabschlussarbeiten und betrieblichen Steuererklärungen wird ein monatlicher Vorschuss
            in Höhe von 90% der voraussichtlichen Gebühren (Bemessungsgrundlage: Honorarangebot bzw. Vorjahreshonorar)
            für das jeweilige laufende Jahr vereinbart.
          </div>
        </template>
        <communicator :address="address" :config="config" v-if="1" />
        <refill :config="config" />
      </v-content>
    </div>
  </div>
</template>

<script>
import CurrencyField from '@/components/CurrencyField';
import Ergebnis from '@/components/Ergebnis';
import config from '../data/config';
import Refill from '@/components/Refill.vue';
import Communicator from '@/components/Communicator.vue';
import adjust_iframe_height from '@/inneres';

export default {
  data() {
    return {
      address: config.address,
      config: config.year
    };
  },
  components: {
    CurrencyField,
    Communicator,
    Ergebnis,
    Refill
  },
  computed: {
    inputs() {
      return eval('this.config.' + this.config.selected + '.inputs');
    },
    includedServices() {
      return eval('this.config.' + this.config.selected + '.includedServices');
    },
    gegenstandswert() {
      return eval('this.config.' + this.config.selected + '.result()');
    },
    options() {
      return eval('this.config.' + this.config.selected + '.options');
    }
  },
  methods: {
    adjustHeight(){
      this.$nextTick(function() {
        adjust_iframe_height('est');
      });
    },
  },
};
</script>
