<template>
  <div class="invisible"/>
</template>

<script>
import axios from 'axios';

export default {
  props: ['config'],

  mounted() {
      // console.log(this.$route);

    if (this.$route.name.includes('Recover')) {
      console.log(this.$route);
      axios
        .get(`http://localhost:3000/refill/${this.$route.params.hash}`)
        .then(res => {
          this.config.fill(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
</style>