import stbvv from '@/stbvv.ts';
import CONSTANTS from './variables';

let jahresabschluss = {
    name: 'Jahresabschluss und betriebliche Steuererklärungen',
    priceName: 'Ihre jährlichen Steuerberaterkosten',
    isChecked: false,
    modes: [
        {
            text: 'Einzelunternehmen/Freiberufler/Personengesellschaft mit Einnahmen-Überschussrechnung',
            value: 'EÜR',
        },
        {
            text: 'Einzelunternehmer mit Bilanzierungspflicht',
            value: 'EinzelunternehmerBilanz',
        },
        {
            text: 'GmbH/UG',
            value: 'GmbH',
        },
        {
            text: 'GmbH & Co. KG',
            value: 'GmbHCoKG',
        },
        {
            text: 'Personengesellschaft/Bilanzierer',
            value: 'Personengesellschaft',
        },
    ],
    selected: null,
    getInputs() {
        //@ts-ignore
        let inputs = eval('this.' + this.selected + '.inputs');
        //@ts-ignore
        let options = eval('this.' + this.selected + '.options');

        return {
            selected: this.selected,
            inputs: inputs.map((x: any) => x.value),
            options: options.map((x:any) => x.checked)
        };
    },
    fill(jahrInputs:any) {
        this.selected = jahrInputs.selected;
        let thisInputs = eval('this.' + jahrInputs.selected + '.inputs');
        let thisOptions = eval('this.' + jahrInputs.selected + '.options');
        jahrInputs.inputs.forEach((x:any, i:number) => {
            thisInputs[i].value = x;
        });
        jahrInputs.options.forEach((x:any, i:number) => {
            thisOptions[i].checked = x;
        });
    },
    getTotal: (): number => {
        return jahresabschluss.selected ? eval('jahresabschluss.' + jahresabschluss.selected + '.getTotal()') : 0;
    },
    getAuslagenpauschale: (): number => {
        let checkedOptions = jahresabschluss.selected ? eval('jahresabschluss.' + jahresabschluss.selected + '.options.filter(x => x.checked)') : [];

        let optionPrice = 0;
        checkedOptions.forEach((x: any) => {
            optionPrice += x.getPrice();
        })

        let basePrice = jahresabschluss.selected ? eval('jahresabschluss.' + jahresabschluss.selected + '.getBasePrice()') : 0;
        let bemessungsGrundlage = basePrice + optionPrice;
        let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

        // console.log(basePrice, bemessungsGrundlage, auslagenPauschale);


        return auslagenPauschale;
    },
    EÜR: {
        inputs: [
            {
                name: 'Jahresumsatz',
                value: 0,
            },
            {
                name: 'Betriebsausgaben',
                value: 0,
            },
        ],
        includedServices: [
            'Einnahmen- Überschussrechnung inkl. Kontennachweis',
            'Anlagenspiegel',
            'Umsatzsteuererklärung',
            'Anlage EÜR'
        ],
        options: [
            {
                name: 'Gewerbesteuererklärung',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    //@ts-ignore
                    let umsatz = jahresabschluss.EÜR.inputs.find(x => x.name === 'Jahresumsatz').value;
                    //@ts-ignore
                    let ausgaben = jahresabschluss.EÜR.inputs.find(x => x.name === 'Betriebsausgaben').value;

                    return 0.35 * stbvv.a(Math.max((umsatz - ausgaben), 8000));
                },
            },
            {
                name: 'Feststellungserklärung',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    //@ts-ignore
                    let umsatz = jahresabschluss.EÜR.inputs.find(x => x.name === 'Jahresumsatz').value;
                    //@ts-ignore
                    let ausgaben = jahresabschluss.EÜR.inputs.find(x => x.name === 'Betriebsausgaben').value;
                    // (Tabelle A, 3/10, Gegenstandswert: Gewinn, Mindestgegenstandswert 8.000 €)
                    return 0.3 * stbvv.a(Math.max((umsatz - ausgaben), 8000));
                },
            },
            {
                name: 'Dringliche Fertigstellung',
                info: 'Fertigstellung innerhalb einer Woche nach Eingang der Unterlagen',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let basePrice = jahresabschluss.EÜR.getBasePrice();

                    let checkedOptions = jahresabschluss.EÜR.options.filter(x => x.checked);

                    let optionPrice = 0;
                    checkedOptions.forEach((x) => {
                        if (x.name !== 'Dringliche Fertigstellung') {
                            optionPrice += x.getPrice();
                        }
                    })
                    // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
                    let bemessungsGrundlage = basePrice + optionPrice;
                    return 0.1 * bemessungsGrundlage;
                },
            },
        ],
        getBasePrice: (): number => {
            //@ts-ignore XD ... love it
            let umsatz = jahresabschluss.EÜR.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.EÜR.inputs.find(x => x.name === 'Betriebsausgaben').value;

            // Info: Früher galt: 5-20 Zehntel, Gegenstand mind. 12_500 (Mittelgebühr: 12.5)
            // Seit Ge01.07.2020: 5-30 Zehntel, Gegenstand mind. 17_500 (Mittelgebühr: 17.5)
            let eürBase = Math.max(17500, umsatz, ausgaben);

            // Preis für EÜR: 20/10 des Ergebnisses der Tabelle B vom Gegenstandswert
            let priceEür = 2 * stbvv.b(eürBase);

            // Umsatzsteuererklärung (Tabelle A, 4,5/10, Gegenstandswert: 10% der Umsätze, Mindestgegenstandswert 8.000 €)
            let usteBase = Math.max(8000, 0.1 * umsatz);
            let priceUste = stbvv.a(usteBase) * 0.45;

            return priceEür + priceUste;
        },
        getTotal: (): number => {
            let basePrice = jahresabschluss.EÜR.getBasePrice();

            let checkedOptions = jahresabschluss.EÜR.options.filter(x => x.checked);

            let optionPrice = 0;
            checkedOptions.forEach((x) => {
                optionPrice += x.getPrice();
            })
            // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
            let bemessungsGrundlage = basePrice + optionPrice;
            let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

            // Der Gesamtpreis ist Bemessungsgrundlage plus die Optionspreise plus Auslagenpauschale
            return bemessungsGrundlage + auslagenPauschale;
        },
    },
    EinzelunternehmerBilanz: {
        inputs: [
            {
                name: 'Bilanzsumme',
                tooltip: 'Summe der Aktiva bzw. Summe der Passiva',
                value: 0,
            },
            {
                name: 'Jahresumsatz',
                value: 0,
            },
            {
                name: 'Betriebsausgaben',
                value: 0,
            },
        ],
        includedServices: [
            'Bilanz und Gewinn- und Verlustrechnung nach HGB',
            'Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis',
            'Gewerbesteuererklärung',
            'Umsatzsteuererklärung',
            'E-Bilanz',
            'Anlagenspiegel',
        ],
        options: [
            {
                name: 'Erstellung separater Steuerbilanz',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let gegenstandswert = jahresabschluss.EinzelunternehmerBilanz.getGegenstandswert();
                    return stbvv.b(Math.max(gegenstandswert, 3000)) * 0.6;
                },
            },
            {
                name: 'Bilanzanalyse und Präsentation (1 Stunde)',
                isSelect: false,
                checked: false,
                getPrice: (): number => CONSTANTS.STD_SATZ_HIGH,
            },
            {
                name: 'Erläuterungsbericht',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.15 * jahresabschluss.EinzelunternehmerBilanz.getBasePrice(),
            },
            {
                name: 'mit Plausibilitätsbeurteilung',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.35 * jahresabschluss.EinzelunternehmerBilanz.getBasePrice(),
            },
            {
                name: 'Dringliche Fertigstellung',
                info: 'Fertigstellung innerhalb einer Woche nach Eingang der Unterlagen',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let basePrice = jahresabschluss.EinzelunternehmerBilanz.getBasePrice();

                    let checkedOptions = jahresabschluss.EinzelunternehmerBilanz.options.filter(x => x.checked);

                    let optionPrice = 0;
                    checkedOptions.forEach((x) => {
                        if (x.name !== 'Dringliche Fertigstellung') {
                            optionPrice += x.getPrice();
                        }
                    })
                    // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
                    let bemessungsGrundlage = basePrice + optionPrice;
                    return 0.1 * bemessungsGrundlage;
                },
            },
        ],
        getGegenstandswert: (): number => {
            //@ts-ignore
            let bilanz = jahresabschluss.EinzelunternehmerBilanz.inputs.find(x => x.name === 'Bilanzsumme').value;
            //@ts-ignore
            let umsatz = jahresabschluss.EinzelunternehmerBilanz.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.EinzelunternehmerBilanz.inputs.find(x => x.name === 'Betriebsausgaben').value;

            return (bilanz + Math.max(umsatz, ausgaben)) / 2;
        },
        getBasePrice: (): number => {
            // Setzt sich aus 5 verschiedenen Posten zusammen (siehe unten, bzw. includedServices, bzw. Excel tabelle)
            // Alles ausser Anlagenspiegel wird einzeln bepreist

            //@ts-ignore
            let umsatz = jahresabschluss.EinzelunternehmerBilanz.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.EinzelunternehmerBilanz.inputs.find(x => x.name === 'Betriebsausgaben').value;

            let gegenstandswert = jahresabschluss.EinzelunternehmerBilanz.getGegenstandswert();

            // Bilanz und Gewinn- und Verlustrechnung nach HGB
            // (Tabelle B, 30/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisBilanzGewinnVerlustHGB = 3 * stbvv.b(Math.max(gegenstandswert, 3000));

            // Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis
            // (Tabelle B, 6/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAbleitungHandelsbilanz = 0.6 * stbvv.b(Math.max(gegenstandswert, 3000));

            // Umsatzsteuererklärung
            // (Tabelle A, 4,5/10, Gegenstandswert: 10% der Umsätze, Mindestgegenstandswert 8.000 €)
            let usteBase = Math.max(8000, 0.1 * umsatz);
            let preisUste = stbvv.a(usteBase) * 0.45;

            // Gewerbesteuererklärung
            // (Tabelle A, 3,5/10, Gegenstandswert: Gewerbeertrag/Gewinn, Mindestgegenstandswert 8.000 €)
            let preisGewSt = 0.35 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // E-Bilanz (1 Std pauschal)
            let preisEBilanz = CONSTANTS.STD_SATZ_HIGH;

            return preisBilanzGewinnVerlustHGB + preisAbleitungHandelsbilanz + preisUste + preisGewSt + preisEBilanz;
        },
        getTotal: (): number => {
            let basePrice = jahresabschluss.EinzelunternehmerBilanz.getBasePrice();

            let checkedOptions = jahresabschluss.EinzelunternehmerBilanz.options.filter(x => x.checked);

            let optionPrice = 0;
            checkedOptions.forEach((x) => {
                optionPrice += x.getPrice();
            })
            // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
            let bemessungsGrundlage = basePrice + optionPrice;
            let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

            // Der Gesamtpreis ist Bemessungsgrundlage plus die Optionspreise plus Auslagenpauschale
            return bemessungsGrundlage + auslagenPauschale;
        },
    },
    GmbH: {
        inputs: [
            {
                name: 'Bilanzsumme',
                tooltip: 'Summe der Aktiva bzw. Summe der Passiva',
                value: 0,
            },
            {
                name: 'Jahresumsatz',
                value: 0,
            },
            {
                name: 'Betriebsausgaben',
                value: 0,
            },
        ],
        includedServices: [
            'Bilanz und Gewinn- und Verlustrechnung nach HGB',
            'Umsatzsteuererklärung',
            'Gewerbesteuererklärung',
            'Körperschaftsteuererklärung',
            'Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis',
            'E-Bilanz',
            'Offenlegung Bundesanzeiger',
            'Anhang',
            'Anlagenspiegel',
        ],
        options: [
            {
                name: 'Erstellung separater Steuerbilanz',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let gegenstandswert = jahresabschluss.GmbH.getGegenstandswert();
                    return stbvv.b(Math.max(gegenstandswert, 3000)) * 0.6;
                },
            },
            {
                name: 'Bilanzanalyse und Präsentation (1 Stunde)',
                isSelect: false,
                checked: false,
                getPrice: (): number => CONSTANTS.STD_SATZ_HIGH,
            },
            {
                name: 'Erläuterungsbericht',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.15 * jahresabschluss.GmbH.getBasePrice(),
            },
            {
                name: 'mit Plausibilitätsbeurteilung',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.35 * jahresabschluss.GmbH.getBasePrice(),
            },
            {
                name: 'Dringliche Fertigstellung',
                info: 'Fertigstellung innerhalb einer Woche nach Eingang der Unterlagen',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let basePrice = jahresabschluss.GmbH.getBasePrice();

                    let checkedOptions = jahresabschluss.GmbH.options.filter(x => x.checked);

                    let optionPrice = 0;
                    checkedOptions.forEach((x) => {
                        if (x.name !== 'Dringliche Fertigstellung') {
                            optionPrice += x.getPrice();
                        }
                    })
                    // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
                    let bemessungsGrundlage = basePrice + optionPrice;
                    return 0.1 * bemessungsGrundlage;
                },
            },
        ],
        getGegenstandswert: (): number => {
            //@ts-ignore
            let bilanz = jahresabschluss.GmbH.inputs.find(x => x.name === 'Bilanzsumme').value;
            //@ts-ignore
            let umsatz = jahresabschluss.GmbH.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.GmbH.inputs.find(x => x.name === 'Betriebsausgaben').value;

            return (bilanz + Math.max(umsatz, ausgaben)) / 2;
        },
        getBasePrice: (): number => {
            // GmbH: 7 verschiedenen Posten zusammen (siehe unten, bzw. includedServices, bzw. Excel tabelle)
            // Alles ausser Anlagenspiegel wird einzeln bepreist

            //@ts-ignore
            let umsatz = jahresabschluss.GmbH.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.GmbH.inputs.find(x => x.name === 'Betriebsausgaben').value;

            let gegenstandswert = jahresabschluss.GmbH.getGegenstandswert();

            // Bilanz und Gewinn- und Verlustrechnung nach HGB
            // (Tabelle B, 30/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisBilanzGewinnVerlustHGB = 3 * stbvv.b(Math.max(gegenstandswert, 3000));

            // Umsatzsteuererklärung
            // (Tabelle A, 4,5/10, Gegenstandswert: 10% der Umsätze, Mindestgegenstandswert 8.000 €)
            let usteBase = Math.max(8000, 0.1 * umsatz);
            let preisUste = stbvv.a(usteBase) * 0.45;

            // Gewerbesteuererklärung
            // (Tabelle A, 3,5/10, Gegenstandswert: Gewerbeertrag/Gewinn, Mindestgegenstandswert 8.000 €)
            let preisGewSt = 0.35 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // Körperschaftsteuererklärung
            // (Tabelle A, 5/10, Gegenstandswert: Gewinn, Mindestgegenstandswert 16.000 €)
            let preisKSt = 0.5 * stbvv.a(Math.max((umsatz - ausgaben), 16000));

            // Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis
            // (Tabelle B, 6/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAbleitungHandelsbilanz = 0.6 * stbvv.b(Math.max(gegenstandswert, 3000));

            // E-Bilanz (1 Std pauschal)
            let preisEBilanz = CONSTANTS.STD_SATZ_HIGH;

            // Offenlegung Bundesanzeiger (1 Std pauschal)
            let preisOffenlegung = CONSTANTS.STD_SATZ_HIGH;

            // Anhang
            // (Tabelle B, 7/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAnhang = 0.7 * stbvv.b(Math.max(gegenstandswert, 3000));

            // console.log({preisBilanzGewinnVerlustHGB, preisUste, preisGewSt, preisKSt, preisEBilanz, preisAbleitungHandelsbilanz, preisOffenlegung, preisAnhang});

            return preisBilanzGewinnVerlustHGB + preisUste + preisGewSt + preisKSt + preisEBilanz + preisAbleitungHandelsbilanz + preisOffenlegung + preisAnhang;
        },
        getTotal: (): number => {
            let basePrice = jahresabschluss.GmbH.getBasePrice();

            let checkedOptions = jahresabschluss.GmbH.options.filter(x => x.checked);

            let optionPrice = 0;
            checkedOptions.forEach((x) => {
                optionPrice += x.getPrice();
            })
            // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
            let bemessungsGrundlage = basePrice + optionPrice;
            let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

            // Der Gesamtpreis ist Bemessungsgrundlage plus die Optionspreise plus Auslagenpauschale
            return bemessungsGrundlage + auslagenPauschale;
        },
    },
    GmbHCoKG: {
        inputs: [
            {
                name: 'Bilanzsumme',
                tooltip: 'Summe der Aktiva bzw. Summe der Passiva',
                value: 0,
            },
            {
                name: 'Jahresumsatz',
                value: 0,
            },
            {
                name: 'Betriebsausgaben',
                value: 0,
            },
        ],
        includedServices: [
            'Bilanz und Gewinn- und Verlustrechnung nach HGB',
            'Umsatzsteuererklärung',
            'Gewerbesteuererklärung',
            'Gesonderte und einheitliche Feststellungserklärung',
            'Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis',
            'E-Bilanz',
            'Offenlegung Bundesanzeiger',
            'Anhang',
            'Anlagenspiegel',
            'Kapitalkontenentwicklung'
        ],
        options: [
            {
                name: 'Erstellung separater Steuerbilanz',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let gegenstandswert = jahresabschluss.GmbHCoKG.getGegenstandswert();
                    return stbvv.b(Math.max(gegenstandswert, 3000)) * 0.6;
                },
            },
            {
                name: 'Bilanzanalyse und Präsentation (1 Stunde)',
                isSelect: false,
                checked: false,
                getPrice: (): number => CONSTANTS.STD_SATZ_HIGH,
            },
            {
                name: 'Erläuterungsbericht',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.15 * jahresabschluss.GmbHCoKG.getBasePrice(),
            },
            {
                name: 'mit Plausibilitätsbeurteilung',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.35 * jahresabschluss.GmbHCoKG.getBasePrice(),
            },
            {
                name: 'Dringliche Fertigstellung',
                info: 'Fertigstellung innerhalb einer Woche nach Eingang der Unterlagen',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let basePrice = jahresabschluss.GmbHCoKG.getBasePrice();

                    let checkedOptions = jahresabschluss.GmbHCoKG.options.filter(x => x.checked);

                    let optionPrice = 0;
                    checkedOptions.forEach((x) => {
                        if (x.name !== 'Dringliche Fertigstellung') {
                            optionPrice += x.getPrice();
                        }
                    })
                    // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
                    let bemessungsGrundlage = basePrice + optionPrice;
                    return 0.1 * bemessungsGrundlage;
                },
            },
        ],
        getGegenstandswert: (): number => {
            //@ts-ignore
            let bilanz = jahresabschluss.GmbHCoKG.inputs.find(x => x.name === 'Bilanzsumme').value;
            //@ts-ignore
            let umsatz = jahresabschluss.GmbHCoKG.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.GmbHCoKG.inputs.find(x => x.name === 'Betriebsausgaben').value;

            return (bilanz + Math.max(umsatz, ausgaben)) / 2;
        },
        getBasePrice: (): number => {
            // GmbHCoKG: 7 verschiedenen Posten zusammen (siehe unten, bzw. includedServices, bzw. Excel tabelle)
            // Alles ausser Anlagenspiegel und Kapitalkontenentwicklung wird einzeln bepreist

            //@ts-ignore
            let umsatz = jahresabschluss.GmbHCoKG.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.GmbHCoKG.inputs.find(x => x.name === 'Betriebsausgaben').value;

            let gegenstandswert = jahresabschluss.GmbHCoKG.getGegenstandswert();

            // Bilanz und Gewinn- und Verlustrechnung nach HGB
            // (Tabelle B, 30/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisBilanzGewinnVerlustHGB = 3 * stbvv.b(Math.max(gegenstandswert, 3000));

            // Umsatzsteuererklärung
            // (Tabelle A, 4,5/10, Gegenstandswert: 10% der Umsätze, Mindestgegenstandswert 8.000 €)
            let usteBase = Math.max(8000, 0.1 * umsatz);
            let preisUste = stbvv.a(usteBase) * 0.45;

            // Gewerbesteuererklärung
            // (Tabelle A, 3,5/10, Gegenstandswert: Gewerbeertrag/Gewinn, Mindestgegenstandswert 8.000 €)
            let preisGewSt = 0.35 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // Gesonderte und einheitliche Feststellungserklärung
            // (Tabelle A, 3/10, Gegenstandswert: Gewinn, Mindestgegenstandswert 8.000 €)
            let preisFeststellung = 0.3 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis
            // (Tabelle B, 6/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAbleitungHandelsbilanz = 0.6 * stbvv.b(Math.max(gegenstandswert, 3000));

            // E-Bilanz (1 Std pauschal)
            let preisEBilanz = CONSTANTS.STD_SATZ_HIGH;

            // Offenlegung Bundesanzeiger (1 Std pauschal)
            let preisOffenlegung = CONSTANTS.STD_SATZ_HIGH;

            // Anhang
            // (Tabelle B, 7/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAnhang = 0.7 * stbvv.b(Math.max(gegenstandswert, 3000));

            return preisBilanzGewinnVerlustHGB + preisUste + preisGewSt + preisFeststellung + preisEBilanz + preisAbleitungHandelsbilanz + preisOffenlegung + preisAnhang;
        },
        getTotal: (): number => {
            let basePrice = jahresabschluss.GmbHCoKG.getBasePrice();

            let checkedOptions = jahresabschluss.GmbHCoKG.options.filter(x => x.checked);

            let optionPrice = 0;
            checkedOptions.forEach((x) => {
                optionPrice += x.getPrice();
            })
            // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
            let bemessungsGrundlage = basePrice + optionPrice;
            let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

            // Der Gesamtpreis ist Bemessungsgrundlage plus die Optionspreise plus Auslagenpauschale
            return bemessungsGrundlage + auslagenPauschale;
        },
    },
    Personengesellschaft: {
        inputs: [
            {
                name: 'Bilanzsumme',
                tooltip: 'Summe der Aktiva bzw. Summe der Passiva',
                value: 0,
            },
            {
                name: 'Jahresumsatz',
                value: 0,
            },
            {
                name: 'Betriebsausgaben',
                value: 0,
            },
        ],
        includedServices: [
            'Bilanz und Gewinn- und Verlustrechnung nach HGB',
            'Umsatzsteuererklärung',
            'Gewerbesteuererklärung',
            'Gesonderte und einheitliche Feststellungserklärung',
            'Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis',
            'E-Bilanz',
            'Kapitalkontenentwicklung',
            'Anlagenspiegel',
        ],
        options: [
            {
                name: 'Erstellung separater Steuerbilanz',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let gegenstandswert = jahresabschluss.Personengesellschaft.getGegenstandswert();
                    return stbvv.b(Math.max(gegenstandswert, 3000)) * 0.6;
                },
            },
            {
                name: 'Bilanzanalyse und Präsentation (1 Stunde)',
                isSelect: false,
                checked: false,
                getPrice: (): number => CONSTANTS.STD_SATZ_HIGH,
            },
            {
                name: 'Erläuterungsbericht',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.15 * jahresabschluss.Personengesellschaft.getBasePrice(),
            },
            {
                name: 'mit Plausibilitätsbeurteilung',
                isSelect: false,
                checked: false,
                getPrice: (): number => 0.35 * jahresabschluss.Personengesellschaft.getBasePrice(),
            },
            {
                name: 'Dringliche Fertigstellung',
                info: 'Fertigstellung innerhalb einer Woche nach Eingang der Unterlagen',
                isSelect: false,
                checked: false,
                getPrice: (): number => {
                    let basePrice = jahresabschluss.Personengesellschaft.getBasePrice();

                    let checkedOptions = jahresabschluss.Personengesellschaft.options.filter(x => x.checked);

                    let optionPrice = 0;
                    checkedOptions.forEach((x) => {
                        if (x.name !== 'Dringliche Fertigstellung') {
                            optionPrice += x.getPrice();
                        }
                    })
                    // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
                    let bemessungsGrundlage = basePrice + optionPrice;
                    return 0.1 * bemessungsGrundlage;
                },
            },
        ],
        getGegenstandswert: (): number => {
            //@ts-ignore
            let bilanz = jahresabschluss.Personengesellschaft.inputs.find(x => x.name === 'Bilanzsumme').value;
            //@ts-ignore
            let umsatz = jahresabschluss.Personengesellschaft.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.Personengesellschaft.inputs.find(x => x.name === 'Betriebsausgaben').value;

            return (bilanz + Math.max(umsatz, ausgaben)) / 2;
        },
        getBasePrice: (): number => {
            // Personengesellschaft: 5 verschiedenen Posten zusammen (siehe unten, bzw. includedServices, bzw. Excel tabelle)
            // Alles ausser Anlagenspiegel und Kapitalkontenentwicklung wird einzeln bepreist

            //@ts-ignore
            let umsatz = jahresabschluss.Personengesellschaft.inputs.find(x => x.name === 'Jahresumsatz').value;
            //@ts-ignore
            let ausgaben = jahresabschluss.Personengesellschaft.inputs.find(x => x.name === 'Betriebsausgaben').value;

            let gegenstandswert = jahresabschluss.Personengesellschaft.getGegenstandswert();

            // Bilanz und Gewinn- und Verlustrechnung nach HGB
            // (Tabelle B, 30/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisBilanzGewinnVerlustHGB = 3 * stbvv.b(Math.max(gegenstandswert, 3000));

            // Umsatzsteuererklärung
            // (Tabelle A, 4,5/10, Gegenstandswert: 10% der Umsätze, Mindestgegenstandswert 8.000 €)
            let usteBase = Math.max(8000, 0.1 * umsatz);
            let preisUste = stbvv.a(usteBase) * 0.45;

            // Gewerbesteuererklärung
            // (Tabelle A, 3,5/10, Gegenstandswert: Gewerbeertrag/Gewinn, Mindestgegenstandswert 8.000 €)
            let preisGewSt = 0.35 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // Gesonderte und einheitliche Feststellungserklärung
            // (Tabelle A, 3/10, Gegenstandswert: Gewinn, Mindestgegenstandswert 8.000 €)
            let preisFeststellung = 0.3 * stbvv.a(Math.max((umsatz - ausgaben), 8000));

            // Ableitung des steuerlichen Ergebnisses aus dem Handelsbilanzergebnis
            // (Tabelle B, 6/10, Gegenstandswert Mittelwert aus Bilanzsumme und Umsätze/Aufwand, Mindestgegenstandswert: 3.000 €)
            let preisAbleitungHandelsbilanz = 0.6 * stbvv.b(Math.max(gegenstandswert, 3000));

            // E-Bilanz (1 Std pauschal)
            let preisEBilanz = CONSTANTS.STD_SATZ_HIGH;

            return preisBilanzGewinnVerlustHGB + preisUste + preisGewSt + preisFeststellung + preisEBilanz + preisAbleitungHandelsbilanz;
        },
        getTotal: (): number => {
            let basePrice = jahresabschluss.Personengesellschaft.getBasePrice();

            let checkedOptions = jahresabschluss.Personengesellschaft.options.filter(x => x.checked);

            let optionPrice = 0;
            checkedOptions.forEach((x) => {
                optionPrice += x.getPrice();
            })
            // Die Bemessungsgrundlage ist die Summe aus Grundpreis und Optionspreisen
            let bemessungsGrundlage = basePrice + optionPrice;
            let auslagenPauschale = Math.min(0.2 * bemessungsGrundlage, 20);

            // Der Gesamtpreis ist Bemessungsgrundlage plus die Optionspreise plus Auslagenpauschale
            return bemessungsGrundlage + auslagenPauschale;
        },
    },
};

export default jahresabschluss;
