// --------------------------------------------------------------------------------------------------- //
//
//--------------------------------------------------------------------------------------------------- //
var lastPostMessageHeight = false;
var body = document.body;
//@ts-ignore
function adjust_iframe_height(source) {
   // "*" allows this to post to any parent iframe regardless of domain
   //parent.postMessage(document.body.scrollHeight,"*");
   // das ist evtl besser, wenn wir einen kleinen screen wieder gross ziehen:
   // Bei mehreren iframes innerhalb einer Seite brauchen wir den eindeutigen Namen dieses Kandidaten:
   //parent.postMessage('hr_res:'+window.name+':'+document.documentElement.scrollHeight, "*"); // IE braucht in jedem Fall strings, keine numbers

   // prevent recursive postMessages:
   //@ts-ignore

   if (lastPostMessageHeight && lastPostMessageHeight == document.documentElement.scrollHeight) {
      return;
   }

   window.name && window.name.substr(0, 9) == 'hr_embed_'
      ? parent.postMessage('hr_res:' + window.name + ':' + document.documentElement.scrollHeight + ':' + source, "*")
      : parent.postMessage("" + document.documentElement.scrollHeight, "*")
      ;
   //@ts-ignore
   lastPostMessageHeight = document.documentElement.scrollHeight;
}
adjust_iframe_height('init');

// --------------------------------------------------------------------------------------------------- //
//
//--------------------------------------------------------------------------------------------------- //
window.addEventListener('resize', resizeThrottler, false);
//@ts-ignore
var dddResizeTimeout;
function resizeThrottler() {
   // ignore resize events as long as an actualResizeHandler execution is in the queue
   //@ts-ignore

   if (!dddResizeTimeout) {
      dddResizeTimeout = setTimeout(function () {
         dddResizeTimeout = null;
         actualResizeHandler();
         // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
   }
}

function actualResizeHandler() {
   adjust_iframe_height('hndlr');
}

//@ts-ignore
export default function (source) {
  adjust_iframe_height(source);

  // fire another resize event, because of many lacks we saw in EST (suddenly now)
  setTimeout(function () {
    adjust_iframe_height(source);
  }, 250);
}
