const FIBU_TABLE: Array<Array<number>> = [
    [0, 0],
    [15000, 68],
    [17500, 75],
    [20000, 83],
    [22500, 88],
    [25000, 95],
    [30000, 102],
    [35000, 110],
    [40000, 115],
    [45000, 122],
    [50000, 130],
    [62500, 137],
    [75000, 149],
    [87500, 164],
    [100000, 177],
    [125000, 197],
    [150000, 217],
    [200000, 259],
    [250000, 299],
    [300000, 339],
    [350000, 381],
    [400000, 416],
    [450000, 448],
    [500000, 483],
];

const EST_TABLE: Array<Array<number>> = [
    [300, 29],
    [600, 53],
    [900, 76],
    [1200, 100],
    [1500, 123],
    [2000, 157],
    [2500, 189],
    [3000, 222],
    [3500, 255],
    [4000, 288],
    [4500, 321],
    [5000, 354],
    [6000, 398],
    [7000, 441],
    [8000, 485],
    [9000, 528],
    [10000, 571],
    [13000, 618],
    [16000, 665],
    [19000, 712],
    [22000, 759],
    [25000, 806],
    [30000, 892],
    [35000, 977],
    [40000, 1061],
    [45000, 1146],
    [50000, 1230],
    [65000, 1320],
    [80000, 1411],
    [95000, 1502],
    [110000, 1593],
    [125000, 1683],
    [140000, 1773],
    [155000, 1864],
    [170000, 1954],
    [185000, 2045],
    [200000, 2136],
    [230000, 2275],
    [260000, 2414],
    [290000, 2552],
    [320000, 2697],
    [350000, 2760],
    [380000, 2821],
    [410000, 2882],
    [440000, 2939],
    [470000, 2995],
    [500000, 3051],
    [550000, 3132],
    [600000, 3211],
];

const JAHRESABSCHLUSS_TABLE: Array<Array<number>> = [
    [3000, 46],
    [3500, 54],
    [4000, 64],
    [4500, 72],
    [5000, 81],
    [6000, 91],
    [7000, 99],
    [8000, 109],
    [9000, 114],
    [10000, 120], // <= ACHTUNG 121 vs 120
    [12500, 126], // <= ACHTUNG 127 vs 126
    [15000, 142],
    [17500, 157],
    [20000, 168],
    [22500, 180],
    [25000, 190],
    [37500, 203],
    [50000, 248],
    [62500, 286],
    [75000, 319],
    [87500, 333],
    [100000, 348],
    [125000, 399],
    [150000, 444],
    [175000, 483],
    [200000, 517],
    [225000, 549],
    [250000, 578],
    [300000, 605],
    [350000, 657],
    [400000, 704],
    [450000, 746],
    [500000, 785],
    [625000, 822],
    [750000, 913],
    [875000, 991],
    [1000000, 1062],
    [1250000, 1126],
    [1500000, 1249],
    [1750000, 1357],
    [2000000, 1455],
    [2250000, 1542],
    [2500000, 1621],
    [3000000, 1695],
    [3500000, 1841],
    [4000000, 1971],
    [4500000, 2089],
    [5000000, 2196],
    [7500000, 2566],
    [10000000, 2983],
    [12500000, 3321],
    [15000000, 3603],
    [17500000, 3843],
    [20000000, 4050],
    [22500000, 4314],
    [25000000, 4558],
    [30000000, 5014],
    [35000000, 5433],
    [40000000, 5823],
    [45000000, 6187],
    [50000000, 6532],
];

function isNumeric(val: number | string): boolean {
    const num = (
        typeof val === 'undefined' || val === null || !val
            ? NaN
            : +val
    ); // +val makes it a numeric
    // console.log('is_num', val, num, typeof val === 'number', !isNaN(val), isFinite(val));
    return typeof num === 'number' && !isNaN(num) && isFinite(num);
}

function STBGEBV_FIBU(val: number | string): number {
    if (!val || !isNumeric(val)) {
        return NaN;
    }

    const match = false;
    for (let i = 0; i < FIBU_TABLE.length; i++) {
        if (val <= FIBU_TABLE[i][0]) {
            return FIBU_TABLE[i][1];
        }
    }
    if(typeof(val) == 'string'){
        val = parseFloat(val);
    }
    // vom Mehrbetrag über 500 000 Euro je angefangene 50 000 Euro: +30 Euro
    return 438 + 34 * Math.ceil((val - 500000) / 50000);
};

function STBGEBV_EST(val: any): number {
    if (isNaN(val) || !isNumeric(val)) {
        return NaN;
    }

    const match = false;
    for (let i = 0; i < EST_TABLE.length; i++) {
        if (val <= EST_TABLE[i][0]) {
            return EST_TABLE[i][1];
        }
    }

    return (
        3211
        // vom Mehrbetrag bis 5 000 000 Euro je angefangene 50 000 Euro: +126
        + (141 * Math.ceil((Math.min(5000000, val) - 600000) / 50000))
        // vom Mehrbetrag über 5 000 000 Euro bis 25 000 000 Euro je angefangene 50 000 Euro: +95
        + (val > 5000000 ? (106 * Math.ceil((Math.min(25000000, val) - 5000000) / 50000)) : 0)
        // vom Mehrbetrag über 25 000 000 Euro je angefangene 50 000 Euro: +74
        + (val > 25000000 ? (83 * Math.ceil((val - 25000000) / 50000)) : 0)
    );
}

function stbgebv_jahr(val: any): number {
    if (isNaN(val) || !isNumeric(val)) {
        return NaN;
    }

    let match = false;
    for (let i = 0; i < JAHRESABSCHLUSS_TABLE.length; i++) {
        if (val <= JAHRESABSCHLUSS_TABLE[i][0]) {
            return JAHRESABSCHLUSS_TABLE[i][1];
        }
    }
    return (
        6532
        // vom Mehrbetrag bis 125 000 000 Euro je angefangene 5 000 000 Euro: +230
        + (258 * Math.ceil((Math.min(125000000, val) - 50000000) / 5000000))
        // vom Mehrbetrag über 125 000 000 Euro bis 250 000 000 Euro je angefangene 12 500 000 Euro: +402
        + (val > 125000000 ? (450 * Math.ceil((Math.min(250000000, val) - 125000000) / 12500000)) : 0)
        // vom Mehrbetrag über 250 000 000 Euro je angefangene 25 000 000 Euro: +573
        + (val > 250000000 ? (642 * Math.ceil((val - 250000000) / 25000000)) : 0)
    );
}

export default {
    c: (x: number) => STBGEBV_FIBU(x),
    a: (x: number) => STBGEBV_EST(x),
    b: (x: number) => stbgebv_jahr(x),
};
