import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueCurrencyFilter from 'vue-currency-filter';

import '@/styles/main.sass';

Vue.config.productionTip = false;

Vue.use(VueCurrencyFilter,
  {
    symbol : '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'end',
    symbolSpacing: true
  });

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
