import CONSTANTS from './variables';

let lohnbuchhaltung = {
    name: 'Lohnbuchhaltung',
    priceName: 'Ihre monatlichen Steuerberaterkosten',
    isChecked: false,
    employeeFestlohnCount: 0,
    employeeStundenlohnCount: 0,
    includedServices: [
        'Lohn- und Gehaltsabrechnungen als PDF-Dateien',
        'Elektronische Übermittlung der Lohnsteuer-Anmeldungen',
        'Elektronische Übermittlung der Beitragsnachweise an die Krankenkassen',
        'Führung von Jahreslohnkonten je Arbeitnehmer',
        'Führung Jahreslohnjournal',
        'Lohnsteuerbescheinigungen pro Arbeitnehmer und elektronischer Übermittlung',
        'Übermittlung Jahresentgelt-Meldung zur Sozialversicherung',
        'Sofortmeldungen',
        'Übernahme Ihrer digital bereitgestellten Daten',
        'Datenübermittlung der Zahlungsanweisungen Lohn und Gehalt, vermögenswirksame Leistungen, betriebliche Altersvorsorge, Krankenkassen und Finanzamt',
        'Erstellung Buchungsliste für Finanzbuchhaltung',
        'Digitale Personalakte',
        'Arbeitnehmer Online',
    ],
    options: [
        {
            name: 'Urlaubsstatistik',
            checked: false,
            getPrice: (): number => 3.5 * lohnbuchhaltung.getTotalCount(),
        },
        {
            name: 'Postversand an Arbeitgeber',
            checked: false,
            getPrice: (): number => 1 * lohnbuchhaltung.getTotalCount(),
        },
        {
            name: 'Direktversand an Arbeitnehmer',
            checked: false,
            getPrice: (): number => 1 * lohnbuchhaltung.getTotalCount(),
        },
    ],
    getInputs() {
        return {
            employeeFestlohnCount: this.employeeFestlohnCount,
            employeeStundenlohnCount: this.employeeStundenlohnCount,
            options: this.options.map(x => x.checked)
        };
    },
    fill(lohnInputs: any) {
        this.employeeFestlohnCount = lohnInputs.employeeFestlohnCount;
        this.employeeStundenlohnCount = lohnInputs.employeeStundenlohnCount;

        lohnInputs.options.forEach((x: any, i: number) => {
            this.options[i].checked = x;
        });
    },
    getTotalCount() {
        return this.employeeFestlohnCount + this.employeeStundenlohnCount;
    },
    getAuslagenpauschale(): number {
        return Math.min(0.2 * this.getBemessungsgrundlage(), 20);
    },
    getBemessungsgrundlage() {
        const totalPeople = lohnbuchhaltung.getTotalCount();
        let pricePerEmployee = totalPeople >= 4 ? 17.5 : 20;
        let result = (totalPeople * pricePerEmployee) + (lohnbuchhaltung.employeeStundenlohnCount * 5);
        lohnbuchhaltung.options.forEach((x) => {
            if (x.checked) {
                result += x.getPrice();
            }
        });
        return totalPeople < 1 ? 0 : Math.max(40, result);
    },
    getTotal(): number {
        return this.getBemessungsgrundlage() + this.getAuslagenpauschale();
    },
};

export default lohnbuchhaltung;
