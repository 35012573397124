import stbvv from '@/stbvv.ts';
import CONSTANTS from './variables';

let einkommenssteuer = {
    anlagen: [
        {
            name: 'Einkünfte als Arbeitnehmer',
            min: 1,
            max: 2,
            isChecked: false,
            inputNames: ['Einnahmen', 'Werbungskosten'],
            inputs: [[null, null]],
            getResult: (input: Array<number>): number => input[0] - input[1],
            getTotal: (inputs: Array<Array<number>>): number => {
                let min8k = inputs.map((x: any) => Math.max(x[0], x[1], 8000));
                let stbvvResults = min8k.map((x: any) => stbvv.a(x) / 20 * 6.5);
                return stbvvResults.reduce((x, y) => x + (y ? y : 0), 0);
            },
        },
        {
            name: 'Einkünfte aus Renten',
            min: 1,
            max: 2,
            isChecked: false,
            inputNames: ['Einnahmen', 'Werbungskosten'],
            inputs: [[null, null]],
            getResult: (input: Array<number>): number => input[0] - input[1],
            getTotal: (inputs: Array<Array<number>>): number => {
                let min8k = inputs.map((x: any) => Math.max(x[0], x[1], 8000));
                let stbvvResults = min8k.map((x: any) => stbvv.a(x) / 20 * 6.5);
                return stbvvResults.reduce((x, y) => x + (y ? y : 0), 0);
            },
        },
        {
            name: 'Einkünfte aus Vermietung und Verpachtung',
            min: 1,
            max: 100,
            isChecked: false,
            inputNames: ['Einnahmen', 'Werbungskosten'],
            inputs: [[null, null]],
            getResult: (input: Array<number>): number => input[0] - input[1],
            getTotal: (inputs: Array<Array<number>>): number => {
                let min8k = inputs.map((x: any) => Math.max(x[0], x[1], 8000));
                let stbvvResults = min8k.map((x: any) => stbvv.a(x) / 20 * 6.5);
                return stbvvResults.reduce((x, y) => x + (y ? y : 0), 0);
            },
        },
        {
            name: 'Einkünfte aus Kapitalvermögen',
            min: 1,
            max: 100,
            isChecked: false,
            inputNames: ['Einnahmen', 'Werbungskosten'],
            inputs: [[null, null]],
            getResult: (input: Array<number>): number => input[0] - input[1],
            getTotal: (inputs: Array<Array<number>>): number => {
                let min8k = inputs.map((x: any) => Math.max(x[0], x[1], 8000));
                let stbvvResults = min8k.map((x: any) => stbvv.a(x) / 20 * 6.5);
                return stbvvResults.reduce((x, y) => x + (y ? y : 0), 0);
            },
        },
        {
          name: 'Einkünfte aus selbständiger Tätigkeit',
          min: 1,
          isChecked: false,
          inputNames: ['Einnahmen', 'Ausgaben'],
          inputs: [[null, null]],
          getResult: (input: Array<number>): number => input[0] - input[1],
          getTotal: (inputs: Array<Array<number>>): number => {
            // INFO: they do not want to put individual prices on Anlage S+G
            // because they assume, that those get paid via Jahresabschluss.
            // but they do want to include the totals in terms of §24.1.1
            return 0;
          },
        },
        {
          name: 'Einkünfte aus Gewerbebetrieb',
          min: 1,
          isChecked: false,
          inputNames: ['Einnahmen', 'Ausgaben'],
          inputs: [[null, null]],
          getResult: (input: Array<number>): number => input[0] - input[1],
          getTotal: (inputs: Array<Array<number>>): number => {
            // INFO: they do not want to put individual prices on Anlage S+G
            // because they assume, that those get paid via Jahresabschluss.
            // but they do want to include the totals in terms of §24.1.1
            return 0;
          },
        },
        {
            name: 'Sonstige Einkünfte',
            min: 1,
            max: 2, // dbakan 20200630: Why is this set to max:2 ???
            isChecked: false,
            inputNames: ['Einnahmen', 'Werbungskosten'],
            inputs: [[null, null]],
            getResult: (input: Array<number>): number => input[0] - input[1],
            getTotal: (inputs: Array<Array<number>>): number => {
                let min8k = inputs.map((x: any) => Math.max(x[0], x[1], 8000));
                let stbvvResults = min8k.map((x: any) => stbvv.a(x) / 20 * 6.5);
                return stbvvResults.reduce((x, y) => x + (y ? y : 0), 0);
            },
        },
    ],
    services: [
        'Digitaler Belegaustausch (keine Sortierung und Versendung von Papierunterlagen notwendig)',
        'Einfache Bescheidprüfung, ohne Rückfragen/Einspruch/etc.',
        'Übernahme Empfangsvollmacht für Korrespondenz mit dem Finanzamt',
        'Zur Verfügstellung einer ausführlichen Einkommensteuercheckliste',
        'Elektronische Übermitttlung an das Finanzamt',
        'Erstellung Mantelbogen',
        'Sonderausgaben (z.B. Spenden, Versicherungen)',
        'Außergewöhnliche Belastungen (z.B. Krankheitskosten)',
        'Handwerkerleistungen',
        'Anlage Kind(er)',
        'Anlage ST',
        'Anlage U',
        'Elektronische Übermittlung der Steuererklärung'

    ],
    servicesName: 'Inklusivleistungen',
    options: [
        {
            id: 2,
            name: 'Dringlichkeitszuschlag',
            info: 'EStE wird innerhalb einer Woche nach Eingang der Unterlagen fertiggestellt',
            price(grundpreis: number):number {
                let options = einkommenssteuer.options;
                let checkedOptions = options.filter((x: any) => x.isChecked && x.name !== 'Dringlichkeitszuschlag');

                let optionPrices = checkedOptions.map(x => x.price(grundpreis));
                let optionPrice = optionPrices.reduce((x, y) => x + y, 0);

                let checkedAnlagen = einkommenssteuer.anlagen.filter((x: any) => x.isChecked);
                let anlagenTotals = checkedAnlagen.map((x: any) => x.getTotal(x.inputs));
                let sumAnlagen = anlagenTotals.reduce((x: number, y: number) => x + (y ? y : 0), 0);
                return (sumAnlagen + optionPrice + grundpreis) * 0.1;

            },
            isChecked: false,
        },
        // {
        //     id: 3,
        //     name: 'Steuerklassenwechsel',
        //     info: null,
        //     price: (grundpreis: number) => CONSTANTS.STD_SATZ_HIGH/2,
        //     isChecked: false,
        // },
        // {
        //     id: 4,
        //     name: 'Englische Korrespondenz',
        //     info: null,
        //     price: (grundpreis: number) => grundpreis * 3.5 - grundpreis,
        //     isChecked: false,
        // }
    ],
    optionsName: 'Zusatzleistungen',
    priceName: 'Ihre jährlichen Steuerberaterkosten',
    getInputs: () => {
        return {
            anlagenChecked: einkommenssteuer.anlagen.map(x => x.isChecked),
            anlagen: einkommenssteuer.anlagen.map(x => x.inputs),
            options: einkommenssteuer.options.map(x => x.isChecked),
        };
    },
    fill: (estInputs: any) => {
        estInputs.anlagenChecked.forEach((x: any, i: number) => {

            einkommenssteuer.anlagen[i].isChecked = x;

            einkommenssteuer.anlagen[i].inputs = estInputs.anlagen[i];
        });
        estInputs.options.forEach((x: any, i: number) => {
            einkommenssteuer.options[i].isChecked = x;
        });
    },
    getAuslagenpauschale: () => {
        let bemessungsGrundlage = einkommenssteuer.getBemessungsgrundlage();
        return Math.min(20, (bemessungsGrundlage * 0.2));
    },
    getBemessungsgrundlage: () => {
        let checkedAnlagen = einkommenssteuer.anlagen.filter((x: any) => x.isChecked);
        let anlagenTotals = checkedAnlagen.map((x: any) => x.getTotal(x.inputs));
        let grundpreis = einkommenssteuer.grundpreis();
        let optionTotal = einkommenssteuer.optionTotalFunction();
        let sumAnlagen = anlagenTotals.reduce((x: number, y: number) => x + (y ? y : 0), 0);
        return sumAnlagen + optionTotal + grundpreis;
    },
    getTotal: () => {
        let bemessungsGrundlage = einkommenssteuer.getBemessungsgrundlage();
        let auslagenpauschale = einkommenssteuer.getAuslagenpauschale();
        return bemessungsGrundlage + auslagenpauschale;
    },
    grundpreis: () => {
        let gegenstandswert = einkommenssteuer.gegenstandswert();
        gegenstandswert = gegenstandswert > 8000 ? gegenstandswert : 8000;
        let basispreis = stbvv.a(gegenstandswert) * 3.5 / 10;
        let bescheidpruefung = CONSTANTS.STD_SATZ_HIGH/2;

        return basispreis + bescheidpruefung;
    },
    gegenstandswert: (): number => {
        let checkedAnlagen = einkommenssteuer.anlagen.filter((x: any) => x.isChecked);
        let results = checkedAnlagen.flatMap((x: any) => x.inputs.map((y: any) => x.getResult(y)));

        let res = results.filter((x: number) => x >= 0);

        let sum = res.reduce((x: number, y: number) => x + y, 0);
        return sum ? sum : 0;
    },
    optionTotalFunction: () => {
        let options = einkommenssteuer.options;
        let grundpreis = einkommenssteuer.grundpreis();
        let checkedOptions = options.filter((x: any) => x.isChecked);

        let optionPrices = checkedOptions.map(x => x.price(grundpreis))
        return optionPrices.reduce((x, y) => x + y, 0);
    },
}

export default einkommenssteuer;
