<template>
  <div>
    <div class="main-wrapper">
      <v-content style="max-width: 1200px; margin: 0 auto">
        <h1 class="display-1 mb-6 pb-2" style>{{ config.name }}</h1>

        <h3 class="sec-header">Ihre Angaben</h3>
        <!-- <div>BEI OPTION "VORSYSTEM VORHANDEN" WARNUNG FUER INDIVIDUELLES ANGEBOT</div> -->
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" :sm="getCols(input.type)" v-for="(input, i) in mainInputs" :key="i">
                <CurrencyField
                  v-if="input.type === 'currency'"
                  outlined
                  dense
                  class="currency-input"
                  :label="input.name"
                  v-model="input.value"
                  hide-details
                />
                <v-text-field
                  @keydown="isNumber($event)"
                  min="0"
                  v-if="input.type === 'number'"
                  :label="input.name"
                  v-model="input.value"
                  type="number"
                  class="currency-input"
                  dense
                  hide-details
                  outlined
                />

                <v-tooltip right v-if="input.info" max-width="300">
                  <template v-slot:activator="{ on }">
                    <v-btn icon depressed small v-on="on" >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ option.info }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <h3 class="sec-header">Weitere Informationen</h3>

        <v-card>
          <v-card-text>
            <div v-for="(input, i) in secondaryInputs" :key="i" class="d-flex">
              <v-checkbox
                style="margin: 0 0 -19px 0;"
                class="pa-0"
                :label="input.name"
                v-model="input.value"
              />

              <v-tooltip right v-if="input.info" max-width="300">
                <template v-slot:activator="{ on }">
                  <v-btn icon depressed small v-on="on" style="margin: -1px 0 0 5px;">
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </template>
                <span>{{ input.info }}</span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
        <h3 class="sec-header">Inklusivleistungen</h3>
        <v-card>
          <v-card-text style="font-size:1em;line-height:inherit">
            <ul>
              <li v-for="(option, i) in visibleIncludedServices" :key="i">{{option}}</li>
            </ul>
          </v-card-text>
        </v-card>
        <template v-if="visibleOptions.length">
          <h3 class="sec-header">Zusatzleistungen</h3>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col :cols="getCols(option.type)" v-for="(option, i) in visibleOptions" :key="i" class="d-flex">
                  <v-select
                    v-if="option.type === 'select'"
                    outlined
                    dense
                    :label="option.name"
                    :items="option.items"
                    return-object
                    v-model="option.value"
                    class="currency-input"
                    hide-details
                  />
                  <v-text-field
                    @keydown="isNumber($event)"
                    min="0"
                    v-if="option.type === 'number'"
                    :label="option.name"
                    v-model="option.value"
                    type="number"
                    class="currency-input"
                    dense
                    hide-details
                    outlined
                  />
                  <div style=""
                    v-if="option.type === 'checkbox'"
                  class="d-flex"
                  >
                  <v-checkbox
                    style="margin: -8px 0 -5px 0;"
                    class="pa-0"
                    :label="option.name"
                    v-model="option.value"
                    hide-details
                    @change="adjustHeight()"
                  />
                  <v-tooltip right v-if="option.info" max-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn icon depressed small v-on="on" style="margin: -10px 0 -10px 5px;">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ option.info }}</span>
                  </v-tooltip>

                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <Ergebnis :config="config" />
        <communicator :address="address" :config="config" v-if="1"/>
        <div class="fineprint mt-6">
          <h4 v-if="0">Hinweise:</h4>
          <p>Bitte haben Sie dafür Verständnis, dass die hier ausgewiesene
            Steuerberatergebühren zur Erstellung der Finanzbuchführung geschätzt werden.
            Die tatsächlichen Steuerberatergebühren können hiervon abweichen.</p>
          <p>Die Buchführungsarbeiten werden nach dem vereinbarten Stundensatz abgerechnet.</p>
          <p>Alle weiteren Zusatzarbeiten im Zusammenhang mit der Finanzbuchführung werden nach dem
            vereinbarten Stundensatz abgerechnet (u.a. Einrichtung der Finanzbuchhaltung, Übertrag vom Vorberater,
            Sortierarbeiten, Digitalisierungsarbeiten, Anforderung fehlender Belege, etc.).</p>
          <p>Die mandantenbezogenen DATEV-Gebühren, die nicht über das mitgliedsgebundene Mandantengeschäft
            abgedeckt sind und damit nicht dem Auftraggeber direkt von der DATEV in Rechnung gestellt werden,
            werden als Auslagenersatz jährlich vom Auftragnehmer an den Auftraggeber weiterberrechnet.</p>
          <p>Die Erstellung der Finanzbuchhaltungen erfolgt grundsätzlich mindestens monatlich.</p>
        </div>
        <refill :config="config" />
      </v-content>
    </div>
  </div>
</template>

<script>
import CurrencyField from '@/components/CurrencyField.vue';
import Ergebnis from '@/components/Ergebnis.vue';
import Refill from '@/components/Refill.vue';
import Communicator from '@/components/Communicator.vue';
import config from '@/data/config';
import adjust_iframe_height from '@/inneres';

export default {
  data() {
    return {
      address: config.address,
      config: config.accounting
    };
  },
  components: {
    Communicator,
    CurrencyField,
    Ergebnis,
    Refill
  },
  computed: {
    mainInputs() {
      return this.config.inputs.filter(x => x.type !== 'checkbox');
    },
    secondaryInputs() {
      return this.config.inputs.filter(x => x.type === 'checkbox');
    },
    visibleIncludedServices() {
      return this.config.includedServices
        .filter(x => x.isVisible())
        .map(x => x.text);
    },
    visibleOptions() {
      return this.config.options.filter(x => x.isVisible());
    }
  },
  methods: {
    adjustHeight(){
            this.$nextTick(function() {
        adjust_iframe_height('fibu');
      });
    },
    getCols(type) {
      return type === 'checkbox' ? 12 : 6;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        (charCode < 96 || charCode > 105)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
};
</script>
