import CONSTANTS from './variables';

let finanzbuchhaltung = {
    name: 'Finanzbuchhaltung',
    priceName: 'Ihre monatlichen Steuerberaterkosten',
    isChecked: false,
    inputs: [
        {
            name: 'Ausgangsrechnungen pro Monat',
            type: 'number',
            value: 0,
        },
        {
            name: 'Eingangsrechnungen pro Monat',
            type: 'number',
            value: 0,
        },
        {
            name: 'Kasse vorhanden',
            type: 'checkbox',
            value: false,
        },
        {
            name: 'Betriebliches Bankkonto vorhanden',
            type: 'checkbox',
            value: false,
        },
        {
            name: 'PayPal vorhanden',
            type: 'checkbox',
            value: false,
        },
        {
            name: 'Vorsystem vorhanden',
            info: 'Systeme wie SevDesk, Candis etc. Bitte holen Sie in dem Fall ein individuelles Angebot ein.',
            type: 'checkbox',
            value: false,
        },
        {
          name: 'Kleinunternehmer',
          info: 'Bitte holen Sie sich als Kleinunternehmer ein individuelles Angebot ein.',
          type: 'checkbox',
          value: false,
      },
    ],
    includedServices: [
        {
            text: 'Offene Postenbuchführung (OPOS)',
            isVisible: (): boolean => true,
        },
        {
            text: 'Digitale Buchhaltung mit digitalen Belegaustausch',
            info: '(Digi-Bel ohne eigenen DATEV Unternehmen Online Zugang)',
            isVisible: (): boolean => true,
        },
        {
            text: 'Abgabe Umsatzsteuervoranmeldung',
            isVisible: (): boolean => true,
        },
        {
            text: 'Antrag auf Dauerfristverlängerung',
            isVisible: (): boolean => true,
        },
        {
            text: 'Abgabe Zusammenfassende Meldung',
            isVisible: (): boolean => true,
        },
        {
            text: 'Betriebswirtschaftliche Auswertung, Werteblatt Umsatzsteuervoranmeldung, Summen- und Saldenliste',
            isVisible: (): boolean => true,
        },
        {
            text: 'Controllingreport (sofern Vorjahr vorhanden)',
            //@ts-ignore
            isVisible: (): boolean => true,
        },
    ],
    options: [
        // {
        //     name: 'Offene Postenbuchführung (OPOS)',
        //     info: 'Pflicht bei Bilanzierern',
        //     type: 'checkbox',
        //     value: false,
        //     price: (): number => 0,//finanzbuchhaltung.getGrundpreis(),
        //     isVisible: (): boolean => true,
        // },
        // {
        //     name: 'Eigener DATEV Unternehmen Online Zugang',
        //     info: 'Abrechnung durch DATEV',
        //     //@ts-ignore
        //     value: false,
        //     type: 'checkbox',
        //     price: (): number => 0,
        //     isVisible: (): boolean => true,
        // },
        // {
        //     name: 'Eigener DATEV Unternehmen Online Zugang',
        //     info: 'Ab',
        //     type: 'checkbox',
        //     value: false,
        // },
        // {
        //     name: 'Buchungsrhythmus',
        //     //@ts-ignore
        //     isVisible: (): boolean => true,
        //     type: 'select',
        //     value: {
        //         text: 'monatlich',
        //         price: (): number => 0,
        //     },
        //     items: [
        //         {
        //             text: 'monatlich',
        //             price: (): number => 0,
        //         },
        //         {
        //             text: '14-tägig',
        //             price: (): number => finanzbuchhaltung.getGrundpreis() * 0.1,
        //         },
        //         {
        //             text: 'wöchentlich',
        //             price: (): number => finanzbuchhaltung.getGrundpreis() * 0.15,
        //         },
        //     ],
        // },
        // {
        //     name: 'Zahlungsverkehr vorbereiten in Unternehmen Online',
        //     //@ts-ignore
        //     isVisible: (): boolean => finanzbuchhaltung.options.find(x => x.name === 'Eigener DATEV Unternehmen Online Zugang').value,
        //     type: 'select',
        //     value: {
        //         text: 'nein',
        //         price: (): number => 0,
        //     },
        //     items: [
        //         {
        //             text: 'nein',
        //             price: (): number => 0,
        //         },
        //         {
        //             text: '14-tägig',
        //             price: (): number => finanzbuchhaltung.getGrundpreis() * 0.25,
        //         },
        //         {
        //             text: 'wöchentlich',
        //             price: (): number => finanzbuchhaltung.getGrundpreis() * 0.3,
        //         },
        //     ],
        // },
        // {
        //     name: 'Kurzfristige Fertigstellung',
        //     //@ts-ignore
        //     isVisible: (): boolean => true,
        //     type: 'select',
        //     value: {
        //         text: 'nein',
        //         price: (): number => 0,
        //     },
        //     items: [
        //         {
        //             text: 'nein',
        //             price: (): number => 0,
        //         },
        //         {
        //             text: 'innerhalb von 7 Tagen',
        //             price: (): number => finanzbuchhaltung.getEverythingButDringlichkeit() * 0.25,
        //         },
        //         {
        //             text: 'innerhalb von 48 Stunden',
        //             price: (): number => finanzbuchhaltung.getEverythingButDringlichkeit() * 0.3,
        //         },
        //     ],
        // },
        // {
        //     name: 'BWA-Gespräch (1 Stunde jeweils)',
        //     //@ts-ignore
        //     isVisible: (): boolean => true,
        //     type: 'select',
        //     value: {
        //         text: 'nicht buchen',
        //         price: (): number => 0,
        //     },
        //     items: [
        //         {
        //             text: 'nicht buchen',
        //             price: (): number => 0,
        //         },
        //         {
        //             text: 'jährlich',
        //             price: (): number => CONSTANTS.STD_SATZ_HIGH / 12,
        //         },
        //         {
        //             text: 'halbjährlich',
        //             price: (): number => (CONSTANTS.STD_SATZ_HIGH * 1.75) / 12,
        //         },
        //         {
        //             text: 'vierteljährlich',
        //             price: (): number => (CONSTANTS.STD_SATZ_HIGH * 3.25) / 12,
        //         },
        //     ],
        // },

        // {
        //     name: 'Kosten- und Leistungsrechnung',
        //     //@ts-ignore
        //     isVisible: (): boolean => true,
        //     value: false,
        //     type: 'checkbox',
        //     price: (): number => finanzbuchhaltung.getGrundpreis() * 0.15,
        // },
    ],
    getInputs() {
        return {
            inputs: this.inputs.map(x => x.value),
            options: this.options.map((x: any) => {
                if (x.type === 'select') {
                    return x.items.indexOf(x.value) === -1 ? 0 : x.items.indexOf(x.value);
                }
                return x.value;
            }),
        };
    },
    fill(fibuInputs: any) {
        fibuInputs.inputs.forEach((x: any, i: number) => {
            this.inputs[i].value = x;
        });

        fibuInputs.options.forEach((x: any, i: number) => {
            //@ts-ignore
            if (this.options[i].type === 'select' && x != null) {
              //@ts-ignore
              this.options[i].value = this.options[i].items[x];
            } else {
              //@ts-ignore
              this.options[i].value = x;
            }
        });
    },
    getEverythingButDringlichkeit: () => {
        let grundpreis = finanzbuchhaltung.getGrundpreis();
        let preisOptionen = 0;
        finanzbuchhaltung.options.forEach((x: any) => {
            // nur Optionen berechnen, die angezeigt werden:
            if (x.isVisible() && x.name !== 'Kurzfristige Fertigstellung') {
                // erstmal alle checkboxen, die angewählt sind:
                if (x.type === 'checkbox' && x.value) {
                    preisOptionen += x.price();
                }
                // nun alle selects:
                else if (x.type === 'select') {
                    preisOptionen += x.value.price();
                }
                // else sind dann nur type === 'number'
                else {
                    preisOptionen += x.price();
                }

            }
        });
        return grundpreis + preisOptionen;// + (mitOPOS ? oposPreis : 0);
    },
    getGrundpreis: (): number => {
        //@ts-ignore
        let mitOPOS = true;

        //@ts-ignore
        let eingangsrechnungen = finanzbuchhaltung.inputs.find(x => x.name === 'Eingangsrechnungen pro Monat').value;
        //@ts-ignore
        eingangsrechnungen = parseInt(eingangsrechnungen, 10);
        //@ts-ignore
        let ausgangsrechnungen = finanzbuchhaltung.inputs.find(x => x.name === 'Ausgangsrechnungen pro Monat').value;
        //@ts-ignore
        ausgangsrechnungen = parseInt(ausgangsrechnungen, 10);

        if (mitOPOS) {
            eingangsrechnungen = 2 * eingangsrechnungen;
            ausgangsrechnungen = 2 * ausgangsrechnungen;
        }

        return Math.max(
          CONSTANTS.STD_SATZ_LOW, // mind. 1 Std.
          (eingangsrechnungen + ausgangsrechnungen) / 40 * CONSTANTS.STD_SATZ_LOW
        )
    },
    getAuslagenpauschale: (): number => {
        let bemessungsgrundlage = finanzbuchhaltung.getBemessungsgrundlage();
        return Math.min(20, bemessungsgrundlage * 0.2);
    },
    getBemessungsgrundlage: (): number => {
        let grundpreis = finanzbuchhaltung.getGrundpreis();
        let preisOptionen = 0;
        finanzbuchhaltung.options.forEach((x: any) => {
            // nur Optionen berechnen, die angezeigt werden:
            if (x.isVisible()) {
                // erstmal alle checkboxen, die angewählt sind:
                if (x.type === 'checkbox') {
                    if (x.value) {
                        preisOptionen += x.price();
                    }
                }
                // nun alle selects:
                else if (x.type === 'select') {
                    preisOptionen += x.value.price();
                }
                // else sind dann nur type === 'number'
                else if (x.type === 'number') {
                    preisOptionen += x.price();
                } else {
                    throw new Error('Invalid Options');
                }

            }
        });
        return grundpreis + preisOptionen;
    },
    getTotal: (): number => {
        let bemessungsgrundlage = finanzbuchhaltung.getBemessungsgrundlage();
        let auslagenpauschale = finanzbuchhaltung.getAuslagenpauschale();

        return bemessungsgrundlage + auslagenpauschale;
    },
};
export default finanzbuchhaltung;
