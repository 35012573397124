






import Vue from 'vue';
import adjust_iframe_height from './inneres';

export default Vue.extend({
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$nextTick(function() {
      adjust_iframe_height('main_init');
    });
  }
});
