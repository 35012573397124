import Vue from 'vue';
import VueRouter from 'vue-router';
// import Business from '@/views/Business.vue';
import Est from '@/views/Est.vue';
import Year from '@/views/Year.vue';
import Accounting from '@/views/Accounting.vue';
import Salary from '@/views/Salary.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/lohn',
    name: 'lohn',
    component: Salary,
  },
  {
    path: '/fibu',
    name: 'fibu',
    component: Accounting,
  },
  {
    path: '/jahr',
    name: 'jahr',
    component: Year,
  },
  {
    path: '/est',
    name: 'est',
    component: Est,
  },
  {
    path: '/lohn/:hash',
    name: 'lohnRecover',
    component: Salary,
  },
  {
    path: '/fibu/:hash',
    name: 'fibuRecover',
    component: Accounting,
  },
  {
    path: '/jahr/:hash',
    name: 'jahrRecover',
    component: Year,
  },
  {
    path: '/est/:hash',
    name: 'estRecover',
    component: Est,
  },
  {
    path: '/',
    redirect: { name: 'est' }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
