import lohnbuchhaltung from './lohnbuchhaltung';
import jahresabschluss from './jahresabschluss';
import finanzbuchhaltung from './finanzbuchhaltung';
import einkommenssteuer from './einkommenssteuer';

let config = {
    est: einkommenssteuer,
    salary: lohnbuchhaltung,
    year: jahresabschluss,
    accounting: finanzbuchhaltung,
    address: {
        name: null,
        year: null,
        street: null,
        mail: null,
        phone: null,
        zip: null,
        city: null,
    },
}

export default config;