<template>
  <div>
    <div class="main-wrapper">
      <v-content style="max-width: 1200px; margin: 0 auto">
        <h1 class="display-1 mb-6 pb-2" style>{{ config.name }}</h1>

        <h3 class="sec-header">Anzahl der Beschäftigten</h3>
        <!-- <div>BEI OPTION "VORSYSTEM VORHANDEN" WARNUNG FUER INDIVIDUELLES ANGEBOT</div> -->
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  @keydown="isNumber($event)"
                  min="0"
                  v-model="employeeFestlohnCount"
                  type="number"
                  class="currency-input"
                  dense
                  outlined
                  hide-details
                  label="Festlohn"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  @keydown="isNumber($event)"
                  min="0"
                  v-model="employeeStundenlohnCount"
                  type="number"
                  class="currency-input"
                  dense
                  outlined
                  hide-details
                  label="Stundenlohn"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <h3 class="sec-header">Inklusivleistungen</h3>
        <v-card>
          <v-card-text style="font-size:1em;line-height:inherit">
            <ul>
              <li v-for="(option, i) in config.includedServices" :key="i">{{option}}</li>
            </ul>
          </v-card-text>
        </v-card>
        <h3 class="sec-header mb-7">Zusatzleistungen</h3>
        <v-card>
          <v-card-text>
            <v-checkbox
              style="margin: 0 0 -19px 0;"
              class="pa-0"
              v-for="(option, i) in config.options"
              :key="i"
              v-model="option.checked"
              :label="option.name"
            />
          </v-card-text>
        </v-card>

        <Ergebnis :config="config" />
        <communicator :address="address" :config="config" v-if="1" />
        <div class="fineprint mt-6">
          <p>Alle weiteren Meldungen und Tätigkeiten im Zusammenhang mit der Lohnbuchführung werden nach dem vereinbarten Stundensatz abgerechnet
            (z.B. Einrichtung der Lohnbuchführung, BG-Meldung, Bescheinigungen,
            Antrag auf Erstattung Arbeitsunfähigkeit etc.).</p>
          <p>Die mandantenbezogenen DATEV-Gebühren, die nicht über das mitgliedsgebundene Mandantengeschäft abgedeckt sind
            und damit nicht dem Auftraggeber direkt von der DATEV in Rechnung gestellt werden, werden als Auslagenersatz
            jährlich vom Auftragnehmer an den Auftraggeber weiterberrechnet.</p>
        </div>
        <refill :config="config" />
      </v-content>
    </div>
  </div>
</template>

<script>
import config from '@/data/config';
import Ergebnis from '@/components/Ergebnis.vue';
import Refill from '@/components/Refill.vue';
import Communicator from '@/components/Communicator.vue';

export default {
  components: {
    Communicator,
    Ergebnis,
    Refill
  },
  data() {
    return {
      address: config.address,
      config: config.salary
    };
  },
  computed: {
    employeeFestlohnCount: {
      get: function() {
        return this.config.employeeFestlohnCount;
      },
      set: function(newValue) {
        let asFloat = parseFloat(newValue);
        this.config.employeeFestlohnCount = asFloat ? asFloat : 0;
      }
    },
    employeeStundenlohnCount: {
      get: function() {
        return this.config.employeeStundenlohnCount;
      },
      set: function(newValue) {
        let asFloat = parseFloat(newValue);
        this.config.employeeStundenlohnCount = asFloat ? asFloat : 0;
      }
    },
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
