<template>
  <div>
    <div class="main-wrapper">
      <v-content style="max-width: 1200px; margin: 0 auto">
        <h1 class="display-1 mb-6 pb-2" style>Einkommensteuer</h1>
        <div v-if="0">
          <v-btn @click="fill()">FILL IT!</v-btn>
          <v-btn @click="print()">PRINT IT!</v-btn>
          <pre>{{ inputs }}</pre>
        </div>
        <h3 class="sec-header">Ihre Einkünfte</h3>
        <v-card class="mb-2" v-for="(anlage, i) in config.anlagen" :key="i">
          <v-card-title class="card-title">
            <v-checkbox
              @change="adjustHeight()"
              v-model="anlage.isChecked"
              class="ma-0 pa-0"
              hide-details
              :label="anlage.name"
            />
          </v-card-title>
          <v-expand-transition>
            <template v-if="anlage.isChecked">
              <v-card-text class="py-0 px-5">
                <div v-for="j in anlage.inputs.length" :key="j" :class="{
                  'mt-2': j > 1,
                }">
                  <v-row class="mt-0">
                    <template v-if="j===1">
                      <v-col cols="12" class="d-inline-flex py-0">
                        <div class="d-flex flex-shrink-0 flex-grow-0 align-center mr-1" style="width:24px"></div>
                        <div
                          v-for="(inputName, nameIdx) in anlage.inputNames"
                          :key="nameIdx"
                          class="flex-grow-1 flex-basis-100 py-1 mr-3 flex-1"
                          v-text="inputName"
                        />
                        <div class="flex-grow-0 flex-shrink-0" style="width:30px;text-align: right"></div>
                      </v-col>
                    </template>
                    <v-col cols="12" class="d-inline-flex pt-0">
                      <div class="d-flex flex-shrink-0 flex-grow-0 align-center mr-1" style="width:24px">{{j}}.</div>
                      <CurrencyField
                        v-model="anlage.inputs[j-1][k]"
                        v-for="(input, k) in anlage.inputs[j-1]"
                        :key="k"
                        class="flex-grow-1 currency-input mr-3"
                      />
                      <div class="flex-grow-0 flex-shrink-0" style="width:30px;text-align: right">
                        <v-btn color icon @click="removeAt(i, j-1)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-flex class="text-center pt-4 pb-2">
                  <v-btn depressed text color="#999" @click="addAnlage(i)" dark>
                    <v-icon left>mdi-plus</v-icon>
                    <span style="margin-top: 0px;">Eintrag hinzufügen</span>
                  </v-btn>
                </v-flex>
              </v-card-text>
            </template>
          </v-expand-transition>
        </v-card>

        <h3 class="sec-header">{{ config.servicesName }}</h3>
        <v-card>
          <v-card-text style="font-size:1em;line-height:inherit">
            <ul>
              <li v-for="(option, i) in config.services" :key="i">{{option}}</li>
            </ul>
          </v-card-text>
        </v-card>

        <h3 class="sec-header">{{ config.optionsName }}</h3>
        <v-card>
          <v-card-text>
            <div v-for="(option,i) in config.options" :key="i" class="d-flex">
              <div class style="margin: -19px 0 -19px 0;">
                <v-checkbox v-model="config.options[i].isChecked" :label="option.name"></v-checkbox>
              </div>
              <div class="flex-grow-1 d-inline-flex">
                <div class>
                  <v-tooltip right v-if="option.info" max-width="300">
                    <template v-slot:activator="{ on }">
                      <v-btn icon depressed small v-on="on" style="margin: -1px 0 0 5px;">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ option.info }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <ergebnis :config="config" />
        <communicator :address="address" :config="config" v-if="1"/>
        <refill :config="config" />
        <div class="fineprint mt-6">
          <p>
            Arbeiten, die über das übliche Maß hinausgehen, werden nach dem vereinbarten Stundensatz abgerechnet.
            Hierzu zählen unter anderem: ein hohes Belegaufkommen, umfangreiche Sortierarbeiten,
            Ermittlung von Herstellungskosten von Gebäuden im Bereich der Vermietung,
            Kaufpreisaufteilung für bebaute Grundstücke,
            Ermittlung von Veräußerungsgewinnen bei Einkünften aus Kapitalvermögen und anderes mehr.
          </p>
        </div>
      </v-content>
    </div>
  </div>
</template>

<script>
import config from '@/data/config.ts';
import inputs from '@/data/demoInputs';
import CurrencyField from '@/components/CurrencyField.vue';
import Communicator from '@/components/Communicator.vue';
import Refill from '@/components/Refill.vue';
import Ergebnis from '@/components/Ergebnis.vue';
import adjust_iframe_height from '@/inneres';

export default {
  components: {
    Ergebnis,
    CurrencyField,
    Communicator,
    Refill
  },
  data() {
    return {
      address: config.address,
      config: config.est,
      inputs: config.est.getInputs()
    };
  },
  methods: {
    adjustHeight(){
      this.$nextTick(function() {
        adjust_iframe_height('est');
      });
    },
    fill() {
      this.address = inputs.address;
      this.config.fill(inputs.est);
      this.adjustHeight();
    },
    print() {
      let x = this.config.getInputs();
      this.inputs = x;
      console.log(x);
    },
    anlagenMaxReached(index) {
      return (
        this.config.anlagen[index].inputs.length ===
        this.config.anlagen[index].max
      );
    },
    addAnlage(index) {
      if (!this.anlagenMaxReached(index)) {
        this.config.anlagen[index].inputs.push([null, null]);
        this.adjustHeight();
      }
    },
    removeAt(anlageIdx, inputIdx) {
      if (this.config.anlagen[anlageIdx].inputs.length === 1) {
        this.config.anlagen[anlageIdx].isChecked = false;
        this.config.anlagen[anlageIdx].inputs = [[null, null]];
      } else {
        this.config.anlagen[anlageIdx].inputs.splice(inputIdx, 1);
      }
      this.adjustHeight();
    }
  }
};
</script>
